// export const baseurl = "https://node-cryptobot.mobiloitte.com/"; //staging
export const baseurl = "https://node.warrior-partners.com/"; //live

let base = `${baseurl}api/v1`;
let user = `${base}/user`;
let analytics = `${base}/analytics`;
let wallet = `${base}/wallet`;
let triangularArbitrage = `${base}/triangularArbitrage`;
let directArbitrage = `${base}/directArbitrage`;
let loopArbitrage = `${base}/loopArbitrage`;
let intraArbitrage = `${base}/intraArbitrage`;
let notification = `${base}/notification`;

const Apiconfigs = {
  //user
  userLogin: `${user}/userLogin`,
  getProfile: `${user}/getProfile`,
  editUserProfile: `${user}/editUserProfile`,
  forgotPassword: `${user}/forgotPassword`,
  verifyOTP: `${user}/verifyOTP`,
  resendOTP: `${user}/resendOTP`,
  resetPassword: `${user}/resetPassword`,
  changePassword: `${user}/changePassword`,

  //Triangular Arbitrage
  filterProfitPathsTriangular: `${triangularArbitrage}/filterProfitPaths`,
  tradeProfitPathsTriangular: `${triangularArbitrage}/tradeProfitPaths`,
  listPlacedTradeTriangular: `${triangularArbitrage}/listPlacedTrade`,
  viewPlacedTradeTriangular: `${triangularArbitrage}/viewPlacedTrade`,
  activeBlockvPlacedTradeTriangular: `${triangularArbitrage}/activeBlockPlacedTrade`,
  deletePlacedTradeTriangular: `${triangularArbitrage}/deletePlacedTrade`,
  cancelledOrderTriangular: `${triangularArbitrage}/cancelledOrder`,
  autoTradeOnOffTriangular: `${triangularArbitrage}/autoTradeOnOff`,

  //Loop Arbitrage
  filterProfitPathsLoop: `${loopArbitrage}/filterProfitPaths`,
  listPlacedTradeLoop: `${loopArbitrage}/listPlacedTrade`,
  tradeProfitPathsLoop: `${loopArbitrage}/tradeProfitPaths`,
  viewPlacedTradeLoop: `${loopArbitrage}/viewPlacedTrade`,
  activeBlockPlacedTradeLoop: `${loopArbitrage}/activeBlockPlacedTrade`,
  cancelledOrderLoop: `${loopArbitrage}/cancelledOrder`,
  deletePlacedTradeLoop: `${loopArbitrage}/deletePlacedTrade`,
  autoTradeOnOffLoop: `${loopArbitrage}/autoTradeOnOff`,

  //Intra Arbitrage
  filterProfitPathsIntra: `${intraArbitrage}/filterProfitPaths`,
  listPlacedTradeIntra: `${intraArbitrage}/listPlacedTrade`,
  tradeProfitPathsIntra: `${intraArbitrage}/tradeProfitPaths`,
  activeBlockPlacedTradeIntra: `${intraArbitrage}/activeBlockPlacedTrade`,
  deletePlacedTradeIntra: `${intraArbitrage}/deletePlacedTrade`,
  cancelledOrderIntra: `${intraArbitrage}/cancelledOrder`,
  viewPlacedTradeIntra: `${intraArbitrage}/viewPlacedTrade`,
  autoTradeOnOffIntra: `${intraArbitrage}/autoTradeOnOff`,

  //analytics
  arbitrageData: `${analytics}/arbitrageData`,
  tradingDetails: `${analytics}/tradingDetails`,
  tradingView: `${analytics}/tradingView`,

  //wallet
  connectedExchangeList: `${wallet}/connectedExchangeList`,
  exchangeBalance: `${wallet}/exchangeBalance`,
  listExchange: `${wallet}/listExchange`,
  serverIPAddress: `${wallet}/serverIPAddress`,
  connectExchange: `${wallet}/connectExchange`,
  exchangeCoins: `${wallet}/exchangeCoins`,
  removeConnectedExchange: `${wallet}/removeConnectedExchange`,

  //directArbitrage
  filterProfitPathsDirect: `${directArbitrage}/filterProfitPaths`,
  tradeProfitPathsDirect: `${directArbitrage}/tradeProfitPaths`,
  listPlacedTradeDirect: `${directArbitrage}/listPlacedTrade`,
  viewPlacedTradeDirect: `${directArbitrage}/viewPlacedTrade`,
  activeBlockvPlacedTradeDirect: `${directArbitrage}/activeBlockPlacedTrade`,
  deletePlacedTradeDirect: `${directArbitrage}/deletePlacedTrade`,
  cancelledOrderDirect: `${directArbitrage}/cancelledOrder`,
  autoTradeOnOffDirect: `${directArbitrage}/autoTradeOnOff`,

  //Notifivcation
  listNotification: `${notification}/listNotification`,
  readNotification: `${notification}/readNotification`,
};

export default Apiconfigs;
