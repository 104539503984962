import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoginLayout1 from "src/layouts/LoginLayout1";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    guard: true,
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/dash")),
  },
  {
    guard: true,
    exact: true,
    path: "/warrior",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Homepage")),
  },
  {
    guard: true,
    exact: true,
    path: "/exchange",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Exchange/index")),
  },
  {
    guard: true,
    exact: true,
    path: "/exchang",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Exchange")),
  },
  {
    guard: true,
    exact: true,
    path: "/straking",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Straking")),
  },
  {
    exact: true,
    path: "/view-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ProfileComp/Profile")),
  },
  {
    guard: true,
    exact: true,
    path: "/change-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ProfileComp/SetPassword")),
  },
  {
    guard: true,
    exact: true,
    path: "/WalletBank",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/Wallet/MyWallet")),
  },
  {
    guard: true,
    exact: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Profile")),
  },
  {
    exact: true,
    path: "/direct",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Direct")),
  },
  {
    exact: true,
    guard: true,
    path: "/interexchange",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Interexchange")),
  },
  {
    guard: true,
    exact: true,
    path: "/transaction-history",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Transaction/TransactionHistory")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/triangular",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Triangular")),
  },
  {
    exact: true,
    guard: true,
    path: "/loop",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Loop")),
  },
  {
    guard: true,
    exact: true,
    path: "/intraexchange",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Intraexchange")),
  },
  {
    guard: true,
    exact: true,
    path: "/notification",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Home/Notification")),
  },
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/Auth/LogIn")),
  },
  {
    exact: true,
    path: "/signin",
    layout: LoginLayout,
    component: lazy(() => import("src/views/Auth/LogIn")),
  },
  {
    exact: true,
    path: "/forgot",
    layout: LoginLayout,
    component: lazy(() => import("src/views/Auth/forget-password/index")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/Auth/forget-password-link/OtpVerify")
    ),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/Auth/reset-password/index")),
  },
  {
    exact: true,
    path: "/term",
    layout: LoginLayout1,
    component: lazy(() =>
      import("src/views/pages/Home/Terms-and-condition/Index")
    ),
  },
  {
    exact: true,
    path: "/privacy",
    layout: LoginLayout1,
    component: lazy(() => import("src/views/pages/Home/Privacypolicy")),
  },
  {
    exact: true,
    path: "/about",
    layout: LoginLayout1,
    component: lazy(() => import("src/views/pages/Home/AboutUs.js/About.js")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
