import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  Container,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import TopBar from "../LoginLayout1/LoginTopBar";
import Footer from "../LoginLayout1/Footer1";
// import Term from "src/views/Auth/Terms-and-condition/Index"
const useStyles = makeStyles((theme) => ({
  content: {
    height: "100vh",
    // overflowX: "auto",
    // [theme.breakpoints.only("lg")]: {
    //   maxWidth: "800px",
    //   // marginLeft:"100px"
    // },

    color: "#000",
  },
  left: {
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  mainClass: {
    [theme.breakpoints.only("lg")]: {
      maxWidth: "665px",
      // marginLeft:"70px",
    },
    [theme.breakpoints.only("md")]: {
      // maxWidth:"665px",
      paddingLeft: "40px",
    },
  },

  mainbox: {
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  root: {
    backgroundColor: "#fff",
    "@media (min-width: 1326px)": {
      "& .MuiContainer-root": {
        paddingLeft: "0",
        paddingRight: "0",
      },
    },
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Box className={classes.root}>
        <TopBar />
        <Container maxWidth="lg">
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={7}
              lg={7}
              className={classes.mainClass}
            >
              <Box className={classes.content}>{children}</Box>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} className={classes.left}>
              {window.location.pathname === "/login" ? (
                <>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                  >
                    <img
                      src="images/login.png"
                      alt="dummy"
                      className={classes.mainbox}
                    />
                    <Box className="signupmain"></Box>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                  >
                    <img
                      src="images/forgot.png"
                      alt="dummy"
                      className={classes.mainbox}
                    />
                    <Box className="signupmain"></Box>
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
        </Container>
        {/* {window.location.pathname ==="/term" ? (<>
        <Term/>
      </>) :("")} */}

        <Footer />
      </Box>
    </>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
