import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Drawer,
  Grid,
  Button,
  Box,
  Container,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from "./../../component/Logo";
import Scroll from "react-scroll";
const ScrollLink = Scroll.Link;


const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "16px",
    fontWeight: "400",
    borderRadius: 0,
    minWidth: "auto",
    
    fontFamily: 'Inter',
    margin: "0 3px",
    "&.active": {
      color: "#1a9fd8",
    },
    "&[tabIndex]:focus": {
      color: "#1a9fd8",
    },

    "&:hover": {
      color: "#1a9fd8",
    },
  },
  toolbar: {
    display: "flex",
    padding: "20px 0",
    justifyContent: "space-between",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
    },
  },

  logoDrawer: {
    paddingLeft: "10px",
    width: "200px",
    marginBottom: "15px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    backgroundColor: "#192255",
    width: "260px",
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "2px",
    right: "-10px",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "100px !important",
    },
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
    minHeight: "60px !important",
  },
  menuLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  inerrMenu: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    whiteSpace: "nowrap",
    paddingLeft: "25px",
    alignItems: "center",
    // justifyContent: "flex-end",
    justifyContent: "flex-start",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "block",
      padding: "0",
    },
    "& li": {
      "&.active": {
        background: "red",
      },
    },
  },
  menuul: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    alignItems: "center",
    margin: "0",
    height: "50px",
    justifyContent: "space-between",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "block",
      "& .logoDesk": {
        display: "none",
      },
    },

    "& .buttonRound": {
      width: "41px",
      height: "41px",
      borderRadius: "50%",
      color: "#fff",
      marginLeft: "15px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "20px",
      cursor: "pointer",
      border: "2px solid #FFFFFF",
      [theme.breakpoints.down("md")]: {
        marginBottom: "20px",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "20px",
      },
      "&:hover": {
        background: "#fff",
        color: "#000",
        // border: "2px solid #000",
      },
    },
  },
  btn2: {
    marginLeft: "20px",
    padding: "10px 25px",
    fontSize: "18px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "16px",
    },
  },
  inerrMenuList:{
    padding:"8px",
    color:"#797979"
  },
  mobiledraw:{
    "& li":{
      color:"#fff"
    }
  },
  img:{
    "& a":{
      "& img":{
        width:"200px",
        [theme.breakpoints.down("xs")]: {
          width:"130px"
        },
       
      },
    }
  }
}));


export default function Header({ buttonClick }) {
  const classes = useStyles();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const CloseConnectWallet = () => {
    setOpen(false);
  };

  const {
    toolbar,
    menuul,
    drawerContainer,
    drawericon,
    menuLeft,
    logoDrawer,
    containerHeight,
    inerrMenu,
    btn2,
    inerrMenuList,
    mainHeader,
    menuButton,
    mobiledraw,
    img,
  } = useStyles();
  const history = useHistory();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;
  const isAuthenticate = window.sessionStorage.getItem("token");

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1280
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [location.pathname])

  const displayDesktop = () => {
    return (
      <Container className="p-0">
        <Toolbar className={toolbar}>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ paddingLeft: "0px" }}
          >
            <Grid item xs={10} align="center">
              {" "}
              {menuText}{" "}
            </Grid>
            <Grid item xs={2} align="right">
              {connectBtn}
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <Grid Container spacing={2} direction={"column"}>
              <Grid item>
                <Box>
                  <img
                    className={logoDrawer}
                    src="images/footer.png"
                    alt=""
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box className={mobiledraw}>
                  {menuText}
                </Box>
              </Grid>
              <Grid item>
                <Box mt={19} ml={-1}>
                  {connectBtn}
                </Box>
              </Grid>
            </Grid>
          </div>
        </Drawer>

        <div>{productLogo}</div>

        <Grid container spacing={0} alignItems="center" justifyContent="center">
          <Grid item xs={2} align="right">
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ fontSize: "30px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const productLogo = (
    <Box className={classes.img} >
      <Link to="/" style={{ color: "#ffffff", textDecoration: "none" ,  }}>
        <Logo className="logoImg"  />
      </Link>
    </Box>
  );

  const connectBtn = (
    <Button
      variant="contained"
      size="large"
      color="primary"
      className={btn2}
      
      onClick={() => {
        if (isAuthenticate) {
          history.push("/dashboard");
        } else {
          history.push("/login");
        }
      }}
    >
      {isAuthenticate ? "Dashboard" : "Get Started"}
    </Button>
  );

  const menuText = (
    <nav>
      <ul class={menuul}>
        <Box className={menuLeft}>
          <div className="logoDesk">
            <ScrollLink
              className={menuButton}
              smooth={true}
              duration={500}
              to="section1"
            >
              {" "}
              {productLogo}
            </ScrollLink>
          </div>
        </Box>
        <li>
          <li className="activecolor">
            <ul className={inerrMenu}>
              <li className={inerrMenuList}  >
                {" "}
                <ScrollLink
                  className={menuButton}
                  smooth={true}
                  duration={500}
                  to="home"
                  
                  tabIndex="1"
                  onClick={() => history.push("/?id=home")}
                >
                  {" "}
                  Home{" "}
                </ScrollLink>{" "}
              </li>
              <li className={inerrMenuList}>
                {" "}
                <ScrollLink
                  className={menuButton}
                  smooth={true}
                  duration={500}
                  to="supportedexchanges"
                  tabIndex="2"
                  
                  onClick={() => history.push("/?id=supportedexchanges")}
                >
                  {" "}
                  Supported Exchanges{" "}
                </ScrollLink>{" "}
              </li>
              <li className={inerrMenuList}>
                <ScrollLink
                  className={menuButton}
                  smooth={true}
                  duration={500}
                  to="supportedcryptocurrencies"
                  tabIndex="2"
                  onClick={() => history.push("/?id=supportedcryptocurrencies")}
                >
                  {" "}
                  Supported Cryptocurrencies{" "}
                </ScrollLink>{" "}

              </li>
              <li className={inerrMenuList}>
                <ScrollLink
                  className={menuButton}
                  smooth={true}
                  duration={500}
                  to="arbitragetradingstrategies"
                  tabIndex="2"
                  onClick={() => history.push("/?id=arbitragetradingstrategies")}
                >
                  {" "}
                  Arbitrage Trading Strategies{" "}
                </ScrollLink>{" "}
              </li>
              <li className={inerrMenuList}>
                <ScrollLink
                  className={menuButton}
                  smooth={true}
                  duration={500}
                  to="foundingteam"
                  tabIndex="2"
                  onClick={() => history.push("/?id=foundingteam")}
                >
                  {" "}
                  Founding Team{" "}
                </ScrollLink>{" "}
              </li>
            </ul>
          </li>
        </li>
      </ul>
    </nav>
  );

  return (
    <>
      <AppBar
        className="headerNav"
        elevation={0}
      >
        <Container maxWidth="fixed" className={containerHeight}>
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
    </>
  );
}
