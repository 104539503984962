import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CheckIcon from "@material-ui/icons/Check";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  ClosedButton: {
    background: "linear-gradient(180deg, #6585F4 0%, #3563F6 100%) !important",
    borderRadius: "5px !important",
    padding: "7px 19px !important",
    fontSize: "12px !important",
  },
  ClosedButton1: {
    background: "linear-gradient(180deg, #6585F4 0%, #3563F6 100%) !important",
    borderRadius: "5px !important",
    padding: "7px 19px !important",
    fontSize: "12px !important",
    marginLeft: "4rem",
  },
  SendButton: {
    background: "#636363",
    borderRadius: "5px !important",
    padding: "7px 19px !important",
    fontSize: "12px !important",

    "&:hover": {
      background: "#fff",
      color: "#000",
    },
  },
}));
export default function AlertDialog({
  open,
  handleClose,
  title,
  desc,
  confirmationHandler,
}) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ textAlign: "center" }}
        ></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="h6"
              style={{
                textAlign: "center",

                fontWeight: "700",
                color: "#08080D0",
              }}
            >
              {desc}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box
            style={{
              display: "flex !important",
              justifyContent: "center !important",
              paddingBottom: "30px",
            }}
          >
            <Button
              variant="contained"
              className={classes.ClosedButton}
              onClick={handleClose}
            >
              No
            </Button>

            <Button
              variant="contained"
              className={classes.ClosedButton1}
              onClick={confirmationHandler}
              autoFocus
            >
              Yes
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
