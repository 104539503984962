import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(()=>({

}))
const Logo = (props) => {
const classes = useStyles();
  // return <img src="/images/warriorpattner.svg" alt="Logo" {...props} />;

  return <img src="/images/warriorpattner.png"   alt="Logo" {...props} />;

};

export default Logo;
