import { Container, Box, Typography, Grid } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { BsEnvelope } from "react-icons/bs";
import { FiPhone } from "react-icons/fi";
import Scroll from "react-scroll";
import { useHistory, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";

const ScrollLink = Scroll.Link;

const useStyles = makeStyles((theme) => ({
  img: {
    paddingRight: "10px",
  },
  main: {
    padding: "50px 50px 50px 50px",
    [theme.breakpoints.down("xs")]: {
      padding: "50px 0px 50px 0px",
    },
    borderBottom: "1px solid #CECECE",
    "& a": {
      textDecoration: "none",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#4E547B",
    },
  },
  submain: {
    padding: "50px 50px 40px 50px",
    [theme.breakpoints.down("xs")]: {
      padding: "50px 0px 50px 0px",
    },
    "& a": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "19px",
      color: "rgba(65, 83, 199, 1)",
    },
    cursor: "pointer",
    "& h5": {
      textDecoration: "none",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#4E547B",
    },
  },
  copy: {
    padding: "0px 10px 10px 10px",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]:{
      display: "contents",
      textAlign:"center",
      padding: "20px 0px",
    
    },
    "& h5": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "19px",
      color: "#4E547B",
      padding:"5px 0px"
    },
  },
  footerSection1: {
    marginTop: "50px",
    //  borderTop: "2px solid #CECECE",
    boxShadow: "0px 0px 4px 2px  #CECECE",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "30px",
    },
  },
  menuButton: {
    cursor: "pointer",
  },
  navLinkclass: {
    textDecoration: "none",
    color: "black",
  },
  textColor: {
    color: "#4E547B !important",
    fontSize: "16px",
    [theme.breakpoints.down('sm')]:{
      fontSize: "12px",
    }
  },
}));

export default function Footer2() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box className={classes.footerSection1}>
      <Container>
        <Box>
          <Box
            pt={4}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="images/warriorpattner.png"
              style={{ width: "200px" }}
              alt=""
              className={classes.img}
            />
          </Box>
          {/* <Box style={{display:"flex" , justifyContent:"space-around", alignItems:"center",padding: "20px 150px 20px 150px"}}>
            <Link>Home</Link>
            <Link>Supported Exchanges</Link>
            <Link>Supported Cryptocurrencies</Link>
            <Link>Arbitrage Trading Strategies</Link>
            <Link>Founding Team</Link>
        </Box> */}
          <Box className={classes.main}>
            <Grid container spacing={3} align="center">
              <Grid item xs={12} sm={6} md={4} lg={1}>
                <Typography>
                  <span>
                    {" "}
                    <ScrollLink
                      className={classes.menuButton}
                      smooth={true}
                      duration={500}
                      to="home"
                      tabIndex="1"
                      onClick={() =>
                        history.push({
                          pathname: "/",
                          search: "id=home",
                          state: {
                            check: "login",
                          },
                        })
                      }
                    >
                      {" "}
                      Home
                    </ScrollLink>
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography>
                  <span>
                    {" "}
                    <ScrollLink
                      className={classes.menuButton}
                      smooth={true}
                      duration={500}
                      to="supportedexchanges"
                      tabIndex="1"
                      onClick={() =>
                        history.push({
                          pathname: "/",
                          search: "id=supportedexchanges",
                          state: {
                            check: "login",
                          },
                        })
                      }
                    >
                      {" "}
                      Supported Exchanges
                    </ScrollLink>
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography>
                  <span>
                    {" "}
                    <ScrollLink
                      className={classes.menuButton}
                      smooth={true}
                      duration={500}
                      to="supportedcryptocurrencies"
                      tabIndex="1"
                      onClick={() =>
                        history.push({
                          pathname: "/",
                          search: "id=supportedcryptocurrencies",
                          state: {
                            check: "login",
                          },
                        })
                      }
                    >
                      {" "}
                      Supported Cryptocurrencies
                    </ScrollLink>
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography>
                  <span>
                    {" "}
                    <ScrollLink
                      className={classes.menuButton}
                      smooth={true}
                      duration={500}
                      to="arbitragetradingstrategies"
                      tabIndex="1"
                      onClick={() =>
                        history.push({
                          pathname: "/",
                          search: "id=arbitragetradingstrategies",
                          state: {
                            check: "login",
                          },
                        })
                      }
                    >
                      {" "}
                      Arbitrage Trading Strategies
                    </ScrollLink>
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Typography cursor="pointer">
                  <span>
                    {" "}
                    <ScrollLink
                      className={classes.menuButton}
                      smooth={true}
                      duration={500}
                      to="foundingteam"
                      tabIndex="1"
                      onClick={() =>
                        history.push({
                          pathname: "/",
                          search: "id=foundingteam",
                          state: {
                            check: "login",
                          },
                        })
                      }
                    >
                      {" "}
                      Founding Team{" "}
                    </ScrollLink>
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Box className={classes.submain}>
              <Grid
                container
                spacing={3}
                align="center"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item>
                  <Box style={{ display: "flex" }}>
                    <BsEnvelope
                      style={{
                        color: "#4E547B",
                        paddingRight: "10px",
                        fontSize: "20px",
                      }}
                    />

                    <Link> info@warrior-partners.com</Link>
                  </Box>
                </Grid>
                <Grid item>
                  <Box style={{ display: "flex" }}>
                    <FiPhone
                      style={{ color: "#4E547B", paddingRight: "10px" }}
                    />
                    <Typography variant="h5"> +1 415 699 6465</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.copy}>
              <Typography variant="h5">
                Copyright © 2022 LIMITED, ALL RIGHTS RESERVED
              </Typography>
              <Box display="flex" justifyContent="center">
                <NavLink to="/term" className={classes.navLinkclass}>
                  <Typography variant="body2" className={classes.textColor}>
                    Terms & Conditions
                  </Typography>
                </NavLink>{" "}
                &nbsp;&nbsp; &nbsp;&nbsp;
                <NavLink to="/privacy" className={classes.navLinkclass}>
                  <Typography variant="body2" className={classes.textColor}>
                    Privacy Policy
                  </Typography>
                </NavLink>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <NavLink to="/about" className={classes.navLinkclass}>
                  <Typography variant="body2" className={classes.textColor}>
                    About Us
                  </Typography>
                </NavLink>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
