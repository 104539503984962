/* eslint-disable no-use-before-define */
import React, { useContext, useEffect, useState } from "react";
import { useLocation, matchPath, useHistory, Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { AiFillHome, AiOutlineDashboard } from "react-icons/ai";
import { RiBarChartFill } from "react-icons/ri";
import { GiBackwardTime } from "react-icons/gi";
import { AiFillWallet } from "react-icons/ai";
import { CgTranscript } from "react-icons/cg";
import NavItem from "./NavItem";
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Slide,
} from "@material-ui/core";

const sections = [
  {
    items: [
      {
        title: "Home",
        icon: AiFillHome,
        href: "/",
      },
    ],
  },
  {
    items: [
      {
        title: "Dashboard",
        icon: AiOutlineDashboard,
        // icon:"images/dashicon.png",
        href: "/dashboard",
      },
    ],
  },

  // {

  //   items: [
  //     {
  //       title: "Buy",
  //       icon: FiPaperclip,
  //       href: "",
  //       items: [
  //         {
  //           title: " Warrior",
  //           icon: "",
  //           href: "/warrior",
  //         },
  //         {
  //           title: " Staking",
  //           icon: "",
  //           href: "/straking",
  //         },

  //       ],
  //     },
  //   ],
  // },
  {
    items: [
      {
        title: "Exchanges",
        icon: RiBarChartFill,
        href: "/exchange",
      },
    ],
  },
  {
    items: [
      {
        title: "Arbitrage",
        icon: GiBackwardTime,
        href: "",
        items: [
          {
            title: " Direct",
            icon: "",
            href: "/direct",
          },
          {
            title: " Triangular",
            icon: "",
            href: "/triangular",
          },
          {
            title: " Loop",
            icon: "",
            href: "/loop",
          },
          {
            title: " Intra Exchange",
            icon: "",
            href: "/intraexchange",
          },
          // {
          //   title: " Inter Exchange",
          //   icon: "",
          //   href: "/interexchange",
          // },
        ],
      },
    ],
  },

  {
    items: [
      {
        title: "My Wallet",
        icon: AiFillWallet,
        href: "/WalletBank",
      },
    ],
  },
  {
    items: [
      {
        title: "Transaction History",
        icon: CgTranscript,
        href: "/transaction-history",
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: true,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: "#002B88",
    backdropFilter: "blur(44px)",
  },
  desktopDrawer: {
    width: 256,
    top: 0,
    height: "100%",
    background: "#002B88",
    backdropFilter: "blur(44px)",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  logoicon: {
    display: "flex",
    marginTop: "16px",
    alignItems: "center",
    marginLeft: "30px",
  },

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  logoutButton: {
    textAlign: "left",
    display: "flex",
    justifyContent: "flex-start",
    color: "#fff",

    "&:hover": {
      color: "#fff",
      background: "rgb(90 134 255) !important",
      borderRadius: "none !important",
    },
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: "2px",
  },
  buttonLeaf: {
    color: "#fff",
    padding: "11px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    borderLeft: "solid 8px transparent",
    borderRadius: 0,
    fontSize: "13px",
    borderLeft: "3px solid transparent",
    "&:hover": {
      background: "rgb(90 134 255)",
      color: "#fff",
    },

    "&.depth-0": {
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  active: {
    borderLeft: "3px solid #fff",

    fontWeight: theme.typography.fontWeightRegular,
    background: "#5378F5",
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: "00e0b0",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [colorbg, setColor] = useState();
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const [open1, setOpen1] = React.useState(false);

  const handleClick = (event, index) => {
    setOpen1(!open1);
    setSelectedIndex(index);
  };
  const [isLogout, setIsLogout] = useState(false);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      className="example"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box
          mt={2}
          style={{
            display: "flex",
            paddingLeft: "20px",
            // justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Box>
            <Link to="/">
              <img src="/images/footer.png" width="200px" />
            </Link>
          </Box>
          &nbsp;&nbsp;&nbsp;
        </Box>

        <Box pt={1}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
