import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { getDataHandlerAPI } from "src/apiConfig/service";
import { calculateTimeLeft } from "../utils/index";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `${accessToken}`;
  } else {
    sessionStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.sessionStorage.getItem("token");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({});
  const [connectedExchangeList, setConnectedExchangeList] = useState([]);

  const [endTime, setEndtime] = useState();
  const [timeLeft, setTimeLeft] = useState();
  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime * 1000));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  const getUserProfileDatahandler = async (token) => {
    try {
      const response = await getDataHandlerAPI("getProfile", token);
      if (response) {
        setUserData(response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getConnectedExchangeList = async (token) => {
    try {
      const response = await getDataHandlerAPI("connectedExchangeList", token);
      if (response) {
        setConnectedExchangeList(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("token")) {
      getUserProfileDatahandler(window.sessionStorage.getItem("token"));
      getConnectedExchangeList(window.sessionStorage.getItem("token"));
    }
  }, [window.sessionStorage.getItem("token")]);

  let data = {
    userLoggedIn: isLogin,
    userData,
    timeLeft,
    setTimeLeft,
    setEndtime,
    connectedExchangeList,
    setIsLogin: (data) => setIsLogin(data),
    getUserProfileDatahandler: (data) => getUserProfileDatahandler(data),
    getConnectedExchangeList: (data) => getConnectedExchangeList(data),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
