import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiPickersBasePicker: {
      containerLandscape: {
        flexDirection: "column"
      }
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        alignItems: "center",
        flexDirection: "column"
      }
    },
    MuiFormLabel: {
      root: { color: "#222" },
      colorSecondary: {
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        color: "##797979",
        backgroundColor: "#ffffff",
        "@media (max-width:767px)": {
          paddingTop:"7px"
        },
       
      }

    },
    MuiListSubheader: {
      root: {
        color: "#000000",
        fontSize: "22px !important",
        fontWeight: "600 !important",
        lineHeight: "33px !important",
      },
    },
    MuiMenuItem: {
      root: {
        color: "#848484",
      },
    },
    MuiOutlinedInput: {
      colorSecondary: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          color: "#222",
          borderColor: "#222",
        },
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiAutocomplete: {
      option: {
        color: "#000",
      },
    },
    MuiPaper: {
      outlined: {
        padding: "20px",
        width: "100%",
      },
    },
    MuiPopover: {
      root: {
        zIndex: 99999,
      },
    },
    MuiListItem: {
      root: {
        alignItems: "self-start",
      },
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px",
        fontSize: "12px",
      },
      colorSecondary: {
        "&.Mui-checked": { color: "#000" },
      },
    },
    MuiFormControlLabel: {
      root: {
        paddingBottom: "0",
      },
    },
    MuiToolbar: {
      gutters: {
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
      },
    },
    MuiTabs: {
      flexContainer: {
        background: "#002b88",
      },
    },

    MuiPickersClockPointer: {
      thumb: {
        border: "14px solid #002b88",
      },
    },

    // MuiSwitch:{
    //     switchBase:{
    //         color:"#3563F6",
    //     }},
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "10.5px 14px",
      },
    },
    MuiDialog: {
      //       container:{
      // background:"red",
      //       },
      paperWidthSm: {
        // width: "470px !important",
        maxWidth: "400 !important",
      },
      paperScrollPaper: {
        // Width: 450,
        // maxWidth: "100%",
      },
      paper: {
        overflowY: "unset",
      },
      // paperWidthSm:{
      //   Width: "700px !important",
      // },
    },
    MuiInputBase: {
      input: {
        fontSize: 14,
        color: "#222",
        height: "2.1876em",
      },
    },
    MuiBackdrop: {
      root: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
    },

    MuiPickersToolbar: {
      toolbarLandscape: {
        maxWidth: "100%"
      },
      toolbar: {
        backgroundColor: "#002B88",
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#002B88",
      },
    },
    MuiButton: {
      containedSecondary: {
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "50px",
        color: "#fff",
        fontSize: "14px",
        backgroundColor: "#f30065",
        padding: "5px 19px",
      },
      MuiOutlinedInput: {
        notchedOutline: {
          borderColor: "rgb(0 0 0 / 3%) !important",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        },
      },

      // "& .MuiOutlinedInput-notchedOutline":{
      //   borderColor: 'rgb(0 0 0 / 3%) !important',
      // },

      containedPrimary: {
        borderRadius: "10px",
        color: "#FFF",
        fontSize: "18px !important",
        width: "190px",
        height: "60px",
        fontWeight: "700px",
        fontFamily: "Inter",
        fontStyle: "normal",

        background: "linear-gradient(180deg, #6585F4 0%, #3563F6 100%)",

        "@media (max-width:767px)": {
          fontSize: "14px !important",
          width: "150px",
          height: "50px",
        },
        "&:hover": {
          backgroundColor: "#f30065",

          color: "#fff",
        },
      },
      contained: {
        borderRadius: "10px",
        color: "#FFF",
        fontSize: "18px !important",

        padding: "12px 25px !important",
        background: "linear-gradient(180deg, #6585F4 0%, #3563F6 100%)",

        "@media (max-width:767px)": {
          fontSize: "14px !important",
        },
        "&:hover": {
          backgroundColor: "#f30065",

          color: "#fff",
        },
      },
      outlinedPrimary: {
        borderRadius: "10px",
        color: "#fff",
        fontSize: "18px !important",
        width: "190px",
        height: "60px",
        fontWeight: "700px",
        fontFamily: "Inter",
        fontStyle: "normal",
        border: "1px solid #DBD9D9",
        "@media (max-width:767px)": {
          fontSize: "14px !important",
          width: "150px",
          height: "50px",
        },
        "&:hover": {
          // backgroundColor:"#f30065",
          border: "1px solid #DBD9D9",
          color: "#fff",
        },
      },
      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
    MuiMenu: {
      paper: { top: "47px" },
    },

    MuiTypography: {
      subtitle1: {
        color: "#000",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: " 16px",
        colorSecondary: {
          color: "#8d8989",
        },
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Poppins', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#898989",
      dark: "#de0d0d",
      light: "#de0d0d",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
