export default {
  h1: {
    fontWeight: "800",
    fontSize: 35,
    fontFamily:'Inter',
    fontStyle:"normal",
    color:"#fff",
    lineHeight: "80px",
    "@media (max-width:767px)": {
      fontSize: "35px",
      lineHeight: "40px",
    },

  },
  h2: {
    fontWeight: "800",
    fontSize: 30,
    fontFamily:'Inter',
    fontStyle:"normal",
    lineHeight: "60px",
    "@media (max-width:767px)": {
      fontSize: "25px",
      lineHeight: "30px",
    },
  },
  h3: {
    fontWeight: "600",
    fontSize: 25,
    fontFamily:'Inter',
    fontStyle:"normal",
    lineHeight: "24px",
    "@media (max-width:767px)": {
      fontSize: "20px",
      lineHeight: "24px",
    },
  },
  h4: {
    fontWeight: "300",
    fontSize: 20,
    fontFamily:'Inter',
    fontStyle:"normal",
    color:"#fff",
    lineHeight: "22px",
    "@media (max-width:767px)": {
      fontSize: "16px",
      lineHeight: "22px",
    },
  },
  h5: {
    fontWeight: "700",
    fontSize: 18,
    fontFamily:'Inter',
    fontStyle:"normal",
    color:"#fff",
    lineHeight: "61px",
    "@media (max-width:767px)": {
      fontSize: "30px",
      lineHeight: "40px",
    },
  },
  h6: {
    fontWeight: "400",
    fontSize: 16,
    fontFamily:'Inter',
    fontStyle:"normal",
    color:"#000",
    lineHeight: "36px",
    "@media (max-width:767px)": {
      fontSize: "14px",
      lineHeight: "22px",
    },
  },
  overline: {
    fontWeight: 500,
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
  },
  body2: {
    fontSize: 14,
    fontFamily:'Inter',
    fontStyle:"normal",
    fontWeight: "400px",
  },
  body1: {
    fontSize: 12,
    fontFamily:'Inter',
    fontStyle:"normal",
    fontWeight: "400px",
    color:"#FFFAFA",

  },
};
