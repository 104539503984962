import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Hidden,
  SvgIcon,
  InputBase,
  Box,
  Container,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import { Menu as MenuIcon } from "react-feather";
import ConfirmationDialog from "src/component/Confirmation";
import { Dialog } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { alpha } from "@material-ui/core/styles";
import TopBarData from "./TopBarData";
import { useHistory } from "react-router-dom";
import { FiSearch } from "react-icons/fi";

import RightSideDrawer from "./RIghtSideDrawer";
import { IoNotificationsOutline } from "react-icons/io5";
import PerfectScrollbar from "react-perfect-scrollbar";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.secondary.main,
  },
  desktopDrawer1: {
    position: "absolute",
    right: 73,
    top: 0,
    width: "240px !important",
    padding: "12px",
    color: "#000",
    // background: theme.palette.primary.main,
  },
  toolbar: {
    padding: "0 10px",
    float: "right",
    width: "calc(100% - 256px)",
    right: 0,
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    top: -2,
    padding: 0,
    background: "#fff",
    "@media (max-width: 1279px)": {
      width: "100%",
      justifyContent: "space-between",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    border: "1px solid #DBD8FC !important",
    boxShadow:
      "0px 100px 80px rgba(41, 72, 152, 0.05), 0px 64.8148px 46.8519px rgba(41, 72, 152, 0.037963), 0px 38.5185px 25.4815px rgba(41, 72, 152, 0.0303704), 0px 20px 13px rgba(41, 72, 152, 0.025), 0px 8.14815px 6.51852px rgba(41, 72, 152, 0.0196296), 0px 1.85185px 3.14815px rgba(41, 72, 152, 0.012037)",
    borderRadius: "10px",
    width: "400px !important",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "60ch",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },

  FiterBox: {
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
    "& .MuiSelect-selectMenu": {
      color: "#25396F",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      boxShadow: "none !important",
      border: "1px solid #DBD8FC !important",
    },
  },
  icon: {
    fontSize: "25px",
    padding: "0px 20px",
    cursor: "pointer",
  },
  input: {
    borderRadius: "7px",
    background: "#fff",
  },
  top: {
    display: "flex",
    alignItems: "center",
    marginRight: "53px",
    [theme.breakpoints.down("md")]: {
      marginRight: "53px",
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: "33px",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "13px",
    },
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const [age, setAge] = React.useState("Select Exchange");
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <AppBar
      elevation={0}
      className={clsx(classes.root, className)}
      color="inherit"
      {...rest}
    >
      <Container>
        <Toolbar className={classes.toolbar}>
          <Hidden lgUp>
            <IconButton
              color="#fff"
              onClick={onMobileNavOpen}
              style={{ marginRight: 10, padding: "23px" }}
            >
              <SvgIcon fontSize="small">
                <MenuIcon style={{ color: "#197ab3" }} />
              </SvgIcon>
            </IconButton>
          </Hidden>
          {/* <TopBarData /> */}
          {/* <Box>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <FiSearch
                  style={{ color: "rgb(130 133 139)", fontSize: "27px" }}
                />
              </div>
              <InputBase
                placeholder="Search Files..."
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
          </Box> */}
          <Box className={classes.top} >
            <Box className={classes.FiterBox}>
              <Box style={{ display: "flex", alignItems: "center" }}>
                {/* <Box>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={age}
                      onChange={handleChange}
                    >
                      <MenuItem value={"Select Exchange"}>Filter</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                      <MenuItem value={40}>native</MenuItem>
                      <MenuItem value={50}>react</MenuItem>
                    </Select>
                  </FormControl>
                </Box> */}
                <Box>
                  <IoNotificationsOutline
                    className={classes.icon}
                    onClick={() => history.push("/notification")}
                  />
                </Box>
              </Box>
            </Box>

            <RightSideDrawer />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
