import {
  Container,
  Box,
  Typography,
  Grid,
  Avatar,
  Link,
} from "@material-ui/core";
import React from "react";
import TopBar from "./TopBar";
import { makeStyles } from "@material-ui/core";
import { BsEnvelope } from "react-icons/bs";
import { FiPhone } from "react-icons/fi";
import { useHistory, useLocation } from "react-router-dom";
import Scroll from "react-scroll";
import { NavLink } from "react-router-dom";

const ScrollLink = Scroll.Link;
const useStyles = makeStyles((theme) => ({
  img: {
    paddingRight: "10px",
  },
  main: {
    padding: "50px 170px",
    // display: "flex",
    alignItems: "center",
    justifyContent: "center",
    whiteSpace:"pre",
    [theme.breakpoints.down("md")]: {
      padding: "50px 0px 50px 0px",
    },
    borderBottom: "1px solid #CECECE",
    "& a": {
      textDecoration: "none",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#fff",
    },
  },
  submain: {
    padding: "50px 50px 40px 50px",
    [theme.breakpoints.down("xs")]: {
      padding: "30px 0px 30px 0px",
    },
    "& a": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#fff",
    },
    "& h5": {
      textDecoration: "none",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#fff",
    },
  },
  copy: {
    padding: "0px 10px 10px 10px",
    display: "flex",
    justifyContent: "space-between",
    color: "white",
    [theme.breakpoints.down("xs")]:{
      display: "contents",
      textAlign:"center",
      padding: "20px 0px",
    
    },
    "& h5": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "19px",
      color: "#fff",
      padding:"5px 0px"
    },
  },
  footerSection1: {
    //  borderTop: "2px solid #CECECE",
    boxShadow: "0px 0px 4px 2px  #CECECE",
    background: "#003FA6",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "30px",
    },
  },
  box1: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "36px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0px",
    },
  },
  menuLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  menuButton: {
    fontSize: "17px",
    fontWeight: "400",
    borderRadius: 0,
    minWidth: "auto",
    color: "#FFFFFF !important",
    cursor: "pointer",
    padding: "0px 5px",
    margin: "0 5px",
    "&.active": {
      color: "#898989",
    },
    "&[tabIndex]:focus": {
      color: "#898989",
    },

    "&:hover": {
      color: "#898989",
    },
  },
  textColor: {
    color: "#fff !important",
    fontSize: "16px",
    [theme.breakpoints.down('sm')]:{
      fontSize: "12px",
    }
  },
  navLinkclass: {
    textDecoration: "none",
  },
  gridflex:{
    marginLeft:"-31px",
    [theme.breakpoints.down('sm')]:{
      marginLeft:"0",
    }
  },
  gridflex1:{
   marginLeft:"30px",
   [theme.breakpoints.down('md')]:{
    marginLeft:"0px",
  }
  }
}));

export default function Footer2() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box className={classes.footerSection1}>
      <Container maxWidth="lg">
        <Box>
          <Box
            pt={4}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="images/footer.png"
              width="200px"
              alt=""
              className={classes.img}
            />
          </Box>
          <Box className={classes.main} align="center">
            <Typography variant="h6">
              {/* <ul className={classes.inerrMenu}> */}
              <Grid container spacing={2} >
              <Grid item xs={12} md={1} >
              <span>
              {" "}
              <ScrollLink
                className={classes.menuButton}
                smooth={true}
                duration={500}
                to="home"
                tabIndex="1"
                onClick={() =>
                  history.push({
                    pathname: "/",
                    saerch: "id=home",

                    state: {
                      check: "home",
                    },
                  })
                }
              >
                {" "}
                Home{" "}
              </ScrollLink>{" "}
            </span>
              </Grid>
              <Grid item xs={12} md={3}>
              <span>
              {" "}
              <ScrollLink
                className={classes.menuButton}
                smooth={true}
                duration={500}
                to="supportedexchanges"
                tabIndex="2"
                onClick={() => history.push("/?id=supportedexchanges")}
              >
                {" "}
                Supported Exchanges{" "}
              </ScrollLink>{" "}
            </span>
              </Grid>
              <Grid item xs={12} md={4} className={classes.gridflex} >
              <span>
              <ScrollLink
                className={classes.menuButton}
                smooth={true}
                duration={500}
                to="supportedcryptocurrencies"
                tabIndex="2"
                onClick={() => history.push("/?id=supportedcryptocurrencies")}
              >
                {" "}
                Supported Cryptocurrencies{" "}
              </ScrollLink>{" "}
            </span>
              </Grid>
              <Grid item xs={12}  md={3} className={classes.gridflex}>
              <span>
              <ScrollLink
                className={classes.menuButton}
                smooth={true}
                duration={500}
                to="arbitragetradingstrategies"
                tabIndex="2"
                onClick={() =>
                  history.push("/?id=arbitragetradingstrategies")
                }
              >
                {" "}
                Arbitrage Trading Strategies{" "}
              </ScrollLink>{" "}
            </span>
              </Grid>
              <Grid item xs={12}  md={1} className={classes.gridflex1}>
              <span>
                <ScrollLink
                  className={classes.menuButton}
                  smooth={true}
                  duration={500}
                  to="foundingteam"
                  tabIndex="2"
                  onClick={() => history.push("/?id=foundingteam")}
                >
                  {" "}
                  Founding Team{" "}
                </ScrollLink>{" "}
              </span>
              </Grid>
              
              </Grid>
             
             
             
            
              
              {/* </ul> */}
            </Typography>
          </Box>
          <Box>
            <Box className={classes.submain}>
              <Grid
                container
                spacing={3}
                align="center"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item>
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <Avatar style={{ background: "#fff" }}>
                      <BsEnvelope
                        style={{
                          color: "#000",
                          fontSize: "20px",
                        }}
                      />
                    </Avatar>{" "}
                    &nbsp;&nbsp;
                    <Typography variant="body2" style={{color:"#fff"}} >Email :</Typography>
                    &nbsp;&nbsp;
                    <Link href="mailto:info@warrior-partners.com">
                      <Typography variant="body2">
                        info@warrior-partners.com
                      </Typography>
                    </Link>
                  </Box>
                </Grid>
                <Grid item>
                  <Box className={classes.box1}>
                    <Avatar style={{ background: "#fff" }}>
                      <FiPhone style={{ color: "#000" }} />
                    </Avatar>
                    &nbsp;&nbsp;
                    <Typography variant="h5">Contact Us :</Typography>
                    &nbsp;&nbsp;
                    <Typography variant="h5"> +1 415 699 6465</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.copy}>
              <Typography variant="h5">
                Copyright © 2022 LIMITED, ALL RIGHTS RESERVED
              </Typography>
              <Box display="flex" justifyContent="center" >
                <NavLink to="/term" className={classes.navLinkclass}>
                  <Typography variant="body2" className={classes.textColor}>
                    Terms & Conditions
                  </Typography>
                </NavLink>{" "}
                &nbsp;&nbsp; &nbsp;&nbsp;
                <NavLink to="/privacy" className={classes.navLinkclass}>
                  <Typography variant="body2" className={classes.textColor}>
                    Privacy Policy
                  </Typography>
                </NavLink>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <NavLink to="/about" className={classes.navLinkclass}>
                  <Typography variant="body2" className={classes.textColor}>
                    About Us
                  </Typography>
                </NavLink>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
